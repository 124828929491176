
import { goatsList, breedList } from '../data/data'

export default class gotAnimals {

    getAllGoat() {
        return goatsList;
    }

    getZaanenGoats() {
        const result = goatsList.filter(elem => {
            return elem === 'zaanen'
        })
        return result
    }

    getBurskGoats() {
        const result = goatsList.filter(elem => {
            return elem === 'bursk'
        })
        return result
    }

    getToggyGoats() {
        const result = goatsList.filter(elem => {
            return elem === 'toggy'
        })
        return result
    }

    getNubianGoats() {
        const result = goatsList.filter(elem => {
            return elem === 'nubian'
        })
        return result
    }

    getAlpineGoats() {
        const result = goatsList.filter(elem => {
            return elem === 'alipine'
        })
        return result
    }

    getGoatByType(type) {
        const result = goatsList.filter(elem => {
            return elem.type === type
        })
        return result
    }



    getAllBreed() {
        return breedList;
    }

    getZaanenBreed() {
        const result = breedList.filter(elem => {
            return elem === 'zaanen'
        })
        return result
    }

    getBurskBreed() {
        const result = breedList.filter(elem => {
            return elem === 'bursk'
        })
        return result
    }

    getToggyBreed() {
        const result = breedList.filter(elem => {
            return elem === 'toggy'
        })
        return result
    }

    getNubianBreed() {
        const result = breedList.filter(elem => {
            return elem === 'nubian'
        })
        return result
    }

    getAlpineBreed() {
        const result = breedList.filter(elem => {
            return elem === 'alipine'
        })
        return result
    }


}
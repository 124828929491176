import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const FliterButtonDesctop = (props) => {
    const {name, ischoise, filter, onUpdateActualFilter} = props;
    let btnStyle = 'btn d-flex flex-row';
    if(ischoise) {
        btnStyle = 'btn d-flex flex-row font-green'
    } 
    return(
        <div className={btnStyle} onClick={() => onUpdateActualFilter(filter)}>{name}</div>
    )
}

const FliterButtonMobile = (props) => {
    const {name, ischoise, filter, onUpdateActualFilter} = props;
    let btnStyle = '';
    if(ischoise) {
        btnStyle = 'font-white'
    } 
    return(
        <DropdownItem className={btnStyle} onClick={() => onUpdateActualFilter(filter)}>{name}</DropdownItem>
        
    )
}

const FiltesPanel = (props) => {
    const {onUpdateActualFilter, filters, deviceType} = props
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

 let panelOfFilters  
  if(deviceType === "desctop") {
    panelOfFilters = filters.map((elem, i) => {
        const {name, filter, ischoise} = elem;
        
            return (
                <FliterButtonDesctop key={i} name={name} onUpdateActualFilter={onUpdateActualFilter} filter={filter} ischoise={ischoise} />
            )
        
    })
    } else {
        const items = filters.map((elem, i) => {
            const {name, filter, ischoise} = elem;
                return (
                    <FliterButtonMobile key={i} name={name} onUpdateActualFilter={onUpdateActualFilter} filter={filter} ischoise={ischoise} />
                )
        })

        panelOfFilters = ( 
            <div className="d-flex justify-content-end">
                 <Dropdown  isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle className="mobile-filter-dropdown" caret>
                        Выберите породу 
                    </DropdownToggle>
                    <DropdownMenu>
                    {/* <DropdownItem header>Header</DropdownItem> */}
                    {items}
                    </DropdownMenu>
                </Dropdown>
            </div>
        )
    }

return (
    <>{panelOfFilters}</>
)

}

export default FiltesPanel;
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import BreedSale from '../../components/animal-shop/breed-sale'



const AlpinePoroda = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Овцы в продаже', url:'#', status:true}]

    const indicators = [
     
  ]

    
  return (
    <div>
    <SEO title="Овцы в продаже"
        description="Овцы в продаже">                          
  </SEO>
  <Layout>
  <TopBunerProduct TopImage={''}  
                  titileH1={'Овцы в продаже'}
                  comment={""}
                  FormView={true}
                  LogoView={false}
                  typeofBunner={'descrition-page'}
                  breadCrumbs={breadCramb}
                  indicators={indicators}>
        </TopBunerProduct>
    <Container fluid>
     <BreedSale />
    </Container>
  </Layout>
  
</div>
  );
};

export default AlpinePoroda;
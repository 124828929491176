import React from 'react';
import { Row, Col} from 'reactstrap'
import ImagesView from '../../images-view/images-view'
import { Link } from "gatsby"


const ItemCard = (props) => {
    const {item} = props;
    const {name, price, url, featureImage, photos, type, description, currency, breed} = item;


    return (
        <Row className="w-100 m-2 p-2 item-card"> 
        <Col md="4">
            <Row>
                <Col md="12">
                    <ImagesView galleryImage={[{
                                src: featureImage,
                                width: 3,
                                height: 2,
                                alt: `Фото ${name} в продаже на в племенном центре Петерсдорф`
                            },
                        ]} className="w-100" />
                </Col>
                <Col md="12" className="smalgallery">
                    <ImagesView  galleryImage={photos} />
                </Col>
            </Row>
        </Col>
        <Col md="8" className="card-content">
             <Link to={url}>
                <div>
                    <div>
                        <h3>{name}</h3>
                        <p>Порода: {breed}</p>
                    </div>
                    <div>
                       <p className="card-description"> {description.length >180? description.substring(0, 250)+'...': description}</p>
                    </div>
                    
                </div>
                <div className="w-100">
                    <span class="separator-fullScreen mt-1 mb-3 d-none d-md-block"></span>
                    <div className="price-selector">
                    <p>Цена: <span className="animal-price">{price}</span> {currency}</p>
                           
                    </div>
                    <div className="animal-card-else">
                        Подробнее
                    </div>
                </div> 
            </Link>           
        </Col>
    </Row>
    )
}

export default ItemCard;
import React from  'react';
import './spinner.css'


const spinner = () => {

    return (
        <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
        </div>
    )
}

export default spinner;

